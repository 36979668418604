const lazies = Array.from(document.querySelectorAll('.lazy'));

function isInViewport(elem) {
	const rect = elem.getBoundingClientRect();

	return (
		rect.bottom >= 0 &&
		rect.right >= 0 &&

		rect.top <= (
			window.innerHeight ||
			document.documentElement.clientHeight) &&

		rect.left <= (
			window.innerWidth ||
			document.documentElement.clientWidth)
	);
}

function lazyLoad() {
	lazies.forEach((lazy, i) => {
		if (isInViewport(lazy)) {
			lazy.src = lazy.getAttribute('data-src');
			lazies.splice(i, 1);
		}
	});
}

lazyLoad();
window.addEventListener('scroll', lazyLoad);

