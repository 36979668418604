function initMap() {
	// estilização do mapa
	var styledMapType = new google.maps.StyledMapType(
		[
		    {
		        "featureType": "all",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "lightness": "-94"
		            },
		            {
		                "color": "#000000"
		            },
		            {
		                "weight": "0.76"
		            },
		            {
		                "saturation": "81"
		            }
		        ]
		    },
		    {
		        "featureType": "administrative",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "color": "#444444"
		            }
		        ]
		    },
		    {
		        "featureType": "landscape",
		        "elementType": "all",
		        "stylers": [
		            {
		                "color": "#f2f2f2"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "all",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "road",
		        "elementType": "all",
		        "stylers": [
		            {
		                "saturation": -100
		            },
		            {
		                "lightness": 45
		            }
		        ]
		    },
		    {
		        "featureType": "road.highway",
		        "elementType": "all",
		        "stylers": [
		            {
		                "visibility": "simplified"
		            }
		        ]
		    },
		    {
		        "featureType": "road.arterial",
		        "elementType": "labels.icon",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "transit",
		        "elementType": "all",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "water",
		        "elementType": "all",
		        "stylers": [
		            {
		                "color": "#46bcec"
		            },
		            {
		                "visibility": "off"
		            }
		        ]
		    }
		], 
		{name: 'Styled Map'});

	// Create a map object and specify the DOM element for display.
	function addMarker(location, map) {
	  // Add the marker at the clicked location, and add the next-available label
	  // from the array of alphabetical characters.
	  var image = 'assets/frontend/img/map-marker.png'; // <<<<< INSERIR ARQUIVO AQUI
	  var marker = new google.maps.Marker({
	    position: location,
	    map: map,
	    icon: image
	  });
	}

	var latitude = document.querySelector('#latitude').textContent,
		longitude = document.querySelector('#longitude').textContent,
		myLatlng = new google.maps.LatLng(latitude, longitude);

	var mapOptions = {
	  zoom: 15,
	  center: myLatlng,
	  scrollwheel: false,
	  disableDefaultUI: true,
	  mapTypeControlOptions: {
			 mapTypeIds: 'styled_map'
			}
	};
	
	var map = new google.maps.Map(document.getElementById('map'), mapOptions);

	addMarker(myLatlng, map);
	//Associate the styled map with the MapTypeId and set it to display.
	map.mapTypes.set('styled_map', styledMapType);
	map.setMapTypeId('styled_map');
    
}
