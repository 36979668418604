// EVITA O BUG QUE CARREGA A IMAGEM COM 1PX DE ALTURA
function setHeightAuto(array) {
	array.forEach(function(item) {
		var carousel = document.querySelector(item);
		if(!carousel) return;

		const imgs = carousel.querySelectorAll('img');

		Array.from(imgs).forEach(function(img) {
			img.style.height = 'auto';
		});
	})
}

if (document.querySelector('#calendarioCarousel')) {
	$('#calendarioCarousel').slick({
		loop: true,
		nav: true,
		slidesToShow: 2,
		swipe: true,
		dots: false,
		centerPadding: "80px",
		prevArrow: "<i class='fa fa-long-arrow-left arrow-circle--white'></i>",
		nextArrow: "<i class='fa fa-long-arrow-right arrow-circle--white'></i>",
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}

if ($('#turismoGaleria')[0]) {
	$('#turismoGaleria').slick({
		infinite: true,
		slidesToShow: 1,
		swipe: true,
		dots: false,
		prevArrow: "<i class='fa fa-angle-left '></i>",
		nextArrow: "<i class='fa fa-angle-right '></i>"
	});
}

if ($('#carousel')[0]) {
	$('#carousel').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: '#carouselNavs',
		dots: false,
		arrows: false
	});

	setHeightAuto(['#carousel']);
}

if ($('#carouselNavs')[0]) {
	$('#carouselNavs').slick({
		slidesToShow: 2,
		slidesToScroll: 1,
		asNavFor: '#carousel',
		dots: false,
		arrows: true,
		prevArrow: "<i class='fa fa-long-arrow-left arrow-circle--white'></i>",
		nextArrow: "<i class='fa fa-long-arrow-right arrow-circle--white'></i>",
		focusOnSelect: true
	});

	setHeightAuto(['#carouselNavs']);
}

if ($('#calendarioCarousel')[0]) {
	$('#calendarioCarousel').slick({
		loop: true,
		nav: true,
		slidesToShow: 2,
		swipe: true,
		dots: false,
		centerPadding: "80px",
		prevArrow: "<i class='fa fa-long-arrow-left arrow-circle--white'></i>",
		nextArrow: "<i class='fa fa-long-arrow-right arrow-circle--white'></i>",
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}

if ($('#galeriaFotos')[0]) {
	$('#galeriaFotos').slick({
		infinite: true,
		slidesToShow: 3,
		swipe: true,
		dots: false,
		prevArrow: "<i class='fa fa-long-arrow-left arrow-circle--white'></i>",
		nextArrow: "<i class='fa fa-long-arrow-right arrow-circle--white'></i>",
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2
				}
			}]
	});
}
	
