// INICIALIZA O LIGHTBOX
$(document).ready(function(){
	if (typeof $('.lightbox').magnificPopup === typeof Function) {
        $('.lightbox').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true, // set to true to enable gallery
                preload: [0,2], // read about this option in next Lazy-loading section
                navigateByImgClick: true,
                arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button
                tPrev: 'Anterior', // title for left button
                tNext: 'Próximo', // title for right button
                tCounter: '<span class="mfp-counter">%curr% of %total%</span>' // markup of counter
            }
        });
    }
});
	