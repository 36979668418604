$(document).ready(function(){
	function startAccordion(id) {
		var accordion = document.querySelector(id);

		if(!accordion) { //caso não exista o ID do accordion
			return;
		}

		var links = accordion.querySelectorAll('a');	
		var cards = accordion.querySelectorAll('.card');

		var toggle = function(event) { //fechas todas as abas

			var closeTabs = function(arrow) {
				var cardHeaders = accordion.querySelectorAll(id + ' > .card > .card-header');

				cardHeaders.forEach(function(cardHeader){
					cardHeader.classList.remove('active');
				});

				arrow.classList.add('fa-angle-down');
				arrow.classList.remove('fa-angle-up');
			}

			var openTab = function(event) { //abre a aba clicada
				
				var card = event.target.closest('.card');
				var	collapse = card.querySelector('.collapse');
				var cardHeader = card.querySelector('.card-header');
				var arrow = event.target.nextElementSibling;

				if (!collapse.classList.contains('show')) {
					cardHeader.classList.add('active');
					arrow.classList.remove('fa-angle-down');
					arrow.classList.add('fa-angle-up');
				}
			}

			cards.forEach(function(card){
				
				var arrow = card.querySelector('.fa');
				closeTabs(arrow);

			});	

			openTab(event);

		}

		links.forEach(function(link){
			link.addEventListener('click', toggle);
		});
	}

	var innerAccordions = document.querySelectorAll('[id*=inner-accordion]');

	innerAccordions.forEach(function(accordion) {
		startAccordion('#' + accordion.id);
	});

	startAccordion('#accordion');
	startAccordion('#accordion2');

});
