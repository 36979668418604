$(document).ready(function(){
	if (document.querySelector('#destaquesCarousel')) {
		$("#destaquesCarousel").owlCarousel({
			loop: true,
			nav: true,
			items: 1,
			swipe: true,
			dots: false
		});

		$('.carousel-item')[0].classList.add('active'); // coloca a classe active no primeiro slide;
		$('.carousel-indicators > li')[0].classList.add('active'); // coloca a classe active no primeiro slide;
	}
});
