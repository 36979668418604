$(document).ready(function() {

	// {{ POLYFILLS }}
	// Production steps of ECMA-262, Edition 6, 22.1.2.1
	// Reference: https://people.mozilla.org/~jorendorff/es6-draft.html#sec-array.from
	if (!Array.from) {
		Array.from = (function () {
			var toStr = Object.prototype.toString;
			var isCallable = function (fn) {
				return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
			};
			var toInteger = function (value) {
				var number = Number(value);
				if (isNaN(number)) { return 0; }
				if (number === 0 || !isFinite(number)) { return number; }
				return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
			};
			var maxSafeInteger = Math.pow(2, 53) - 1;
			var toLength = function (value) {
				var len = toInteger(value);
				return Math.min(Math.max(len, 0), maxSafeInteger);
			};

			// The length property of the from method is 1.
			return function from(arrayLike/*, mapFn, thisArg */) {
				// 1. Let C be the this value.
				var C = this;

				// 2. Let items be ToObject(arrayLike).
				var items = Object(arrayLike);

				// 3. ReturnIfAbrupt(items).
				if (arrayLike == null) {
					throw new TypeError("Array.from requires an array-like object - not null or undefined");
				}

				// 4. If mapfn is undefined, then let mapping be false.
				var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
				var T;
				if (typeof mapFn !== 'undefined') {
					// 5. else      
					// 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
					if (!isCallable(mapFn)) {
						throw new TypeError('Array.from: when provided, the second argument must be a function');
					}

					// 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
					if (arguments.length > 2) {
						T = arguments[2];
					}
				}

				// 10. Let lenValue be Get(items, "length").
				// 11. Let len be ToLength(lenValue).
				var len = toLength(items.length);

				// 13. If IsConstructor(C) is true, then
				// 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
				// 14. a. Else, Let A be ArrayCreate(len).
				var A = isCallable(C) ? Object(new C(len)) : new Array(len);

				// 16. Let k be 0.
				var k = 0;
				// 17. Repeat, while k < len… (also steps a - h)
				var kValue;
				while (k < len) {
					kValue = items[k];
					if (mapFn) {
						A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
					} else {
						A[k] = kValue;
					}
					k += 1;
				}
				// 18. Let putStatus be Put(A, "length", len, true).
				A.length = len;
				// 20. Return A.
				return A;
			};
		}());
	};
	
	var inputs = {
		init: function(){
			this.masks = {
				phoneInput: '(00) 0000.00009',
				dateInput: 	'00/00/0000',
				cepInput: 	'00000-000',
				cpfInput: 	'000.000.000-00',
				cnpjInput: 	'00.000.000/0000-00'
			};

			for(i in this.masks){
				// Converting camelCase to kebab-case
				var selector = '.' + i.replace(/(?:^|\.?)([A-Z])/g, function (x,y){return "-" + y.toLowerCase()});

				$(selector).mask(this.masks[i], {
					clearIfNotMatch: true
				});
				
				$(selector).attr('maxlength', this.masks[i].length);
			}

			$('input, textarea').placeholder();
		},
	};

	var browserDetect = {
		init: function () {
			this.browser = this.searchString(this.dataBrowser) || "Other";
			this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";

			if(browserDetect.browser == 'Explorer'){
				$('html').addClass('ie ie-' + browserDetect.version);
			}
		},
		searchString: function (data) {
			for (var i = 0; i < data.length; i++) {
				var dataString = data[i].string;
				this.versionSearchString = data[i].subString;

				if (dataString.indexOf(data[i].subString) !== -1) {
					return data[i].identity;
				}
			}
		},
		searchVersion: function (dataString) {
			var index = dataString.indexOf(this.versionSearchString);
			if (index === -1) {
				return;
			}

			var rv = dataString.indexOf("rv:");
			if (this.versionSearchString === "Trident" && rv !== -1) {
				return parseFloat(dataString.substring(rv + 3));
			} else {
				return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
			}
		},

		dataBrowser: [
			{string: navigator.userAgent, subString: "Edge", identity: "MS Edge"},
			{string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
			{string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
			{string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
			{string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
			{string: navigator.userAgent, subString: "Safari", identity: "Safari"},
			{string: navigator.userAgent, subString: "Opera", identity: "Opera"}
		]
	};

	$(function(){
		browserDetect.init();
	});

	// menu-responsivo
	(function() {
		var menu = document.querySelector('#menu-responsivo');
		var botao = document.querySelector('#botao-menu-toggle');
		var body = document.querySelector('body');

		botao.addEventListener('click', function(){
			if ($(menu).hasClass('menu-hidden')) {
				abreMenu();
			} else {
				fechaMenu();
			}
		})

		window.addEventListener('resize', function() {
			if (window.innerWidth > 992) {
				botao.classList.add('hidden');	

				if ($(menu).hasClass('menu-hidden')) {
				} else {
					fechaMenu();
				}
			} else {
				botao.classList.remove('hidden');	
			}
		});

		var fechaMenu = function() {
			menu.classList.add('menu-hidden');
			menu.classList.remove('menu-visible');
			body.classList.remove('noscroll');
			setTimeout(function(){
				menu.classList.add('hidden');
				botao.removeEventListener('click', fechaMenu);
			}, 10);
		};	

		var abreMenu = function() {
			menu.classList.remove('hidden');
			body.classList.add('noscroll');
			setTimeout(function(){
				menu.classList.remove('menu-hidden');
				menu.classList.add('menu-visible');
				botao.addEventListener('click', fechaMenu);
			}, 10);
		};	

	})();

	// SHOWS MENU WHEN SCROLLS UP, HDE MENU WHEN SCROLLS DOWN
	(function() {
		var delta = 5;
		var navbarHeight = $('header').outerHeight();
		var didScroll;
		var lastScrollTop = 0;
	
		$(window).scroll(function(event){
		    didScroll = true;
		});
	
		
		setInterval(function() {
			if ($(window).width() <= 992) {
				if (didScroll) {
				hasScrolled();
					didScroll = false;
				}
			}
		}, 250);

		function hasScrolled() {
		    var st = $(this).scrollTop();
		    
		    // Make sure they scroll more than delta
		    if(Math.abs(lastScrollTop - st) <= delta) {
		        return;
		    }
		    
		    // If they scrolled down and are past the navbar, add class .nav-up.
		    // This is necessary so you never see what is "behind" the navbar.
		    if (st > lastScrollTop && st > navbarHeight){
		        // Scroll Down
		        $('header').removeClass('nav-down').addClass('nav-up');
		    } else {
		        // Scroll Up
		        if(st + $(window).height() < $(document).height()) {
		            $('header').removeClass('nav-up').addClass('nav-down');
		        }
		    }
		    
		    lastScrollTop = st;
		}
	})();
	// END ---- SHOWS MENU WHEN SCROLLS UP, HDE MENU WHEN SCROLLS DOWN

	// ABRE E FECHA O ACESSO RÁPIDO
	(function() {
		var acessoRapidoBotao = document.querySelector('#acessoRapidoBotao');
		var acessoRapido = document.querySelector('#acessoRapido');
		var arrow = document.querySelector('.acesso-rapido-seta');

		function toggleAcessoRapido() {
			$(acessoRapido).slideToggle();
			$(acessoRapidoBotao).toggleClass('active');
			$(arrow).toggleClass('active');
		};

		acessoRapidoBotao.addEventListener('click', toggleAcessoRapido);
		acessoRapidoBotao.addEventListener('keydown', function(event) {
			if (event.keyCode !== 13) return; 
			toggleAcessoRapido();
		});
	})();
	// ABRE E FECHA O ACESSO RÁPIDO

	// MENU MOBILE DROPDOWN
	(function() {

		var menu = document.querySelector('#menuPrimarioMobile');
		var dropdowns = document.querySelectorAll('.menu__dropdown-mobile');

		var fechaDropdowns = function() {
			dropdowns.forEach(function(dropdown){
				dropdown.style.display = 'none';
			});
		}

		var abreDropdown = function(event) {
			
			if(event.target.tagName === 'LI') {
				var dropdown = event.target.querySelector('.menu__dropdown-mobile');
			} else {
				var dropdown = event.target.nextElementSibling;
			}

			if (dropdown.style.display === 'block') {
				dropdown.style.display = 'none';
				return;
			}

			fechaDropdowns();

			dropdown.style.display = 'block';
		}

		dropdowns.forEach(function(dropdown){
			dropdown.style.display = 'none';
			dropdown.removeEventListener('click', abreDropdown);
		});
		menu.addEventListener('click', abreDropdown);

	})();
	// END MENU MOBILE DROPDOWN

	// FUNÇÃO ACTIVE DO MENU SECUNDÁRIO
	(function () {
		var menuSecundario = document.querySelector('#menuSecundario');
		var active = menuSecundario.querySelector('li.active');
		var color = $(active).css('borderColor');
		$(active).css('color', color);
	})();
	// FIM DA FUNÇÃO ACTIVE DO MENU SECUNDÁRIO

	
	// FUNCOES PARA MANIPULAR COOKIES
	function setCookie(name, value, exdays)  {  //função universal para criar cookie
		var expires;
		var date;
		var value;
		date = new Date(); //  criando o COOKIE com a data atual
		date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
		expires = date.toUTCString();
		document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";
	}

	function getCookie(name) {
		var value = "; " + document.cookie;
		var parts = value.split("; " + name + "=");
		return parts.length == 2 ? 
			 parts.pop().split(";").shift(): 
			 false;
	}

	function eraseCookie(name) {
		setCookie(name, -1); // deletando o cookie encontrado a partir do mostraCookie
	}
	// FUNCOES PARA MANIPULAR COOKIES
	
	// CONTROLA TAMANHO DA FONTE DOS PARAGRAFOS
	(function() {
		
		var tamanhos = [8, 10, 12, 14, 16, 18, 20];

		var textos = document.querySelectorAll('.texto-acessivel');
				
		var tamanhoAtual = getCookie("tamanhoDaFonte");

		
		

		if(!tamanhoAtual) tamanhoAtual = 3;
		
		var mudaFonte = function() {
			
			setCookie('tamanhoDaFonte', tamanhoAtual, 15);
			
			textos.forEach(function (texto) {
	
				texto.querySelectorAll('p').forEach(function(p) {
					p.style.fontSize = tamanhos[tamanhoAtual] + 'px';
				})
				
				texto.style.fontSize = tamanhos[tamanhoAtual] + "px";
			});
		}

		var aumenta = function() {

			if(tamanhoAtual === (tamanhos.length - 1)) return;
			
			tamanhoAtual++;
			if(tamanhoAtual >6)
				tamanhoAtual =6;
			
			mudaFonte();
		}
		
		var diminui = function() {

			if(tamanhoAtual === 0) return;
			
			tamanhoAtual--;
			if(tamanhoAtual < 0)
				tamanhoAtual =0;

			mudaFonte();
			
		}

		document.querySelector('#aumenta').addEventListener('click', aumenta);
		document.querySelector('#diminui').addEventListener('click', diminui);
		mudaFonte();

	})();
	// CONTROLA TAMANHO DA FONTE DOS PARAGRAFOS

	// FUNÇÃO QUE MUDA A COR DOS SVGS NAS PAGINAS SECRETARIAS, EMPRESAS ETC...
	(function() {

		var svgs = document.querySelectorAll('object');

		var mudaCor = function(svg) {

			var SVGdocument = svg.getSVGDocument();
			var paths = SVGdocument.querySelectorAll('path');
			var rects = SVGdocument.querySelectorAll('rect');
			var images = SVGdocument.querySelectorAll('image');

			if (paths.length) {
				paths.forEach(function (path) {
					path.setAttribute('fill', '#2e3192');
				});
			}

			if (rects.length) {
				rects.forEach(function (rect) {
					rect.setAttribute('fill', '#2e3192');
				});
			}

			if (images.length) {
				images.forEach(function (image) {
					image.setAttribute('fill', '#2e3192');
				});
			}
		};

		if (!svgs.length) return;

		//Array.from(object).forEach é um fix pro IE que não aceita forEach em objeto
		Array.from(svgs).forEach(function (svg) {
			setTimeout(function () {
				mudaCor(svg);
			}, 500);

			svg.addEventListener('load', function () {
				mudaCor(svg);
			});
		})

		Array.from(svgs).forEach(function (svg) {
			var svgDoc = svg.contentDocument;
			var styleElement = svgDoc.createElementNS("http://www.w3.org/2000/svg", "style");
			styleElement.textContent = "svg { fill: #2e3192 }"; // add whatever you need here
			svgDoc.querySelector("svg").appendChild(styleElement);
		});

	})();
	// FUNÇÃO QUE MUDA A COR DOS SVGS NAS PAGINAS SECRETARIAS, EMPRESAS ETC...

	(function() {
		var dropdownMenus = document.querySelectorAll('.link-with-dropdown');
		
		Array.from(dropdownMenus).forEach(function(elem) {
			elem.childrenLinks = elem.querySelectorAll('ul a');
		});

		function isDropDownOpen(elem) {
			var hasChildFocused = Array.from(elem.childrenLinks).filter(function (link) {
				return link === document.activeElement;
			}).length;

			return hasChildFocused > 0 || elem === document.activeElement;
		}

		function checkDropdowns(event) {
			var TAB_KEY = 9;
			if (event.keyCode !== TAB_KEY) return;

			Array.from(dropdownMenus).forEach(function(elem) {
				if (isDropDownOpen(elem)) {
					elem.classList.add('open');
				} else {
					elem.classList.remove('open');
				}
			});
		}

		document.querySelector('body').addEventListener('keyup', checkDropdowns);
	})();
});

// VALIDAÇÃO DO FORMULÁRIO 
var Formulario = function (elem) {
	var form = $(elem)[0];
	var fields = form.querySelectorAll('input, select, textarea');
	var submitButton = form.querySelector('#submit');
	
	Array.from(fields).forEach(function (field) {
		field.addEventListener('input', validateField);
	});

	function validateField(event) {
		var field = event.target;
		field.validity.valid === false ?
			field.classList.add('invalid') :
			field.classList.remove('invalid');
	}
	
	function setInvalidFields() {
		Array.from(fields).forEach(function (field) {
			field.validity.valid === false ?
				field.classList.add('invalid') :
				field.classList.remove('invalid');
			});
	}
	
	function submitForm() {
		submitButton.textContent = 'Enviando...';	
		//console.dir(form);
		var dados = new FormData(form);		

		$.ajax({
			type: "POST",
			url: form.dataset.action,
			data: dados,
			dataType: 'json',
			processData: false,
			contentType: false,
			success: function (data) {
				console.log(data);
				if (data) {
					if (data.state == 'success') {
						form.reset();
						submitButton.textContent = 'Enviado com sucesso!';
						grecaptcha.reset();
						setTimeout(function () {
							submitButton.textContent = 'Enviar';
						}, 4000);					
					}		
					if (data.state == 'error') {
						alert('Erro ao enviar mensagem.');
						setTimeout(function () {
							submitButton.textContent = 'Enviar';
						}, 4000);
					}
				}
			},
			error: function (data) {
				alert('Erro ao enviar mensagem..');
				setTimeout(function () {
					submitButton.textContent = 'Enviar';
				}, 4000);
			}
		});
	}
	
	function formIsInvalid() {
		return Array.from(fields).filter(function (field) {
			return field.validity.valid === false;
		}).length > 0;
	}
	
	function validateTokenAndSubmitForm(token) {
		$.ajax({
			type: "POST",
			url: './checkrecaptcha/check',
			data: { token },
			success: function (data) {
				var response = JSON.parse(data).success ?
					formulario.submitForm() :
					formulario.submitButton.textContent = 'Erro no captcha';
				},
			error: function (data) {
				formulario.submitButton.textContent = 'Erro no captcha';
			},
		});
	}

	return {
		submitForm,
		submitButton,
		validateTokenAndSubmitForm,
		formIsInvalid,
		setInvalidFields,
	}
}

if ($('#formCaptcha')[0]) {
	console.log('ok entrou aqui');
	var formulario = new Formulario('#formCaptcha');
	
	window.onSubmit = function (token) {
		formulario.validateTokenAndSubmitForm(token);
	};
	
	formulario.submitButton.onclick = (event) => {
		event.preventDefault();
		
		if (formulario.formIsInvalid()) {
			formulario.setInvalidFields();
			return;
		} 
	
		grecaptcha.execute();
	};
}

// FIM VALIDAÇÃO DO FORMULÁRIO
